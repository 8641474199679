import { ethers } from "ethers";
import OxODexFactory from "./abi/OxODexFactory.json";
import OxODexPool from "./abi/OxODexPool.json";
import ERC20 from "./abi/ERC20.json";
import MockToken from "./abi/MockToken.json";

// const contracts = {
//     "OxOFactory": {
//         "address": "0x4Cb9B03b417Ed3052A6a210271683Eb9CBbfe610",
//         "abi": OxOFactory['abi']
//     },
//     "OxOPool": {
//         "abi": OxOPool['abi']
//     },
//     "ERC20": {
//         "abi": ERC20['abi']
//     },
//     "MockToken": {
//         "abi": MockToken['abi']
//     }
// }

// const swapTokens = [
//     {
//         "symbol": "DAI",
//         "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
//         "decimals": 18
//     },
//     {
//         "symbol": "USDC",
//         "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
//         "decimals": 6
//     },
//     {
//         "symbol": "USDT",
//         "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
//         "decimals": 6
//     },
//     {
//         "symbol": "WBTC",
//         "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
//         "decimals": 8
//     },
//     {
//         "symbol": "WETH",
//         "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
//         "decimals": 18
//     }
// ]

// const baseToken = "WETH"
// const chainId = 5;


// For polygon

const contracts = {
    "OxODexFactory": {
        "address": "0xaA77BDd1Df75ac3Fa08a0030D0ba3Aa641f0357e",
        "abi": OxODexFactory['abi']
    },
    "OxODexPool": {
        "abi": OxODexPool['abi']
    },
    "ERC20": {
        "abi": ERC20['abi']
    },
    "MockToken": {
        "abi": MockToken['abi']
    }
}

const swapTokens = [
    {
        "symbol": "DAI",
        "address": "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
        "decimals": 18
    },
    {
        "symbol": "USDC",
        "address": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        "decimals": 6
    },
    {
        "symbol": "USDT",
        "address": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        "decimals": 6
    },
    {
        "symbol": "WBTC",
        "address": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
        "decimals": 8
    },
    {
        "symbol": "WETH",
        "address": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
        "decimals": 18
    }
]

const baseToken = "WMATIC";
const chainId = 137;

export { contracts, swapTokens, baseToken, chainId };