import React, { useState, useRef, useEffect } from 'react'
import Web3 from 'web3';
import Deposit from '../../components/deposit';
import Withdraw from '../../components/withdraw';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { contracts, chainId } from '../../gateway';
import { ethers } from "ethers";

import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../supportedIds";
import allTokens from "../../data/tokens.json";

const web3 = new Web3(Web3.givenProvider);

const Home = () => {

  const tokenRef = useRef();
  const SupportedChainId = chainId;

  const { active, account, activate } = useWeb3React();
  const tokenSearch = useRef();
  const [sortedTokens, setSortedTokens] = useState(allTokens.sort((a, b) => a.symbol.localeCompare(b.symbol)));
  const [pasteToken, setPasteToken] = useState(null);
  const [swapToken, setSwapToken] = useState(
    {
      "address": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      "decimals": 18,
      "displaySymbol": "DAI",
      "logo": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/assets/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/logo.png",
      "name": "(PoS) Dai Stablecoin",
      "symbol": "DAI",
      "website": "http://makerdao.com"
    }
  );

  const [tab, setTab] = useState(0);
  const [OxODexFactory, setOxODexFactory] = useState(null);
  const [depositTokens, setDepositTokens] = useState([]);
  const [msg, setMsg] = useState({
    text: "Connect Wallet",
    color: "54, 210, 205"
  });

  // Metamask states
  const [isNetwork, setIsNetwork] = useState(false);
  const [isLoadingNetwork, setIsLoadingNetwork] = useState(true);
  const [isMetamask, setIsMetamask] = useState(true);
 
  const [user, setUser] = useState({
    address: "",
    balance: 0
  });

  function networkCheck(id) {
    // Checks if the current chain is supported 
    // (5 being goerli testnet as an exception)
    if (id === chainId) {
      setIsNetwork(true);
    }
    else {
      setIsNetwork(false);
    }
    setIsLoadingNetwork(false);
  }

  useEffect(() => {
    // Checks if the network was switched
    const id = setInterval(() => {
      if (web3.givenProvider !== null) {
        setIsMetamask(true);
        web3.eth.net.getId()
          .then(function (result) {
            networkCheck(result);
          })
      }
      else {
        setIsMetamask(false);
      } // <-- (3) invoke in interval callback
    }, 5000);
    // <-- (2) invoke on mount

    return () => clearInterval(id);
  }, [])

  useEffect(() => {
    connectWallet();
  }, [active])

  const switchNetwork = async () => {
    window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{
        chainId: `0x${chainId.toString(16)}`
      }]
    })
      .catch((error) => {
        console.log(error)
      })
  }
 
  useEffect(() => {
    if (web3.givenProvider !== null) {
      web3.eth.net.getId()
      .then(function (result) {
        networkCheck(result);
      });
    }
    else {
      setIsMetamask(false);
    }
  }, []);

  const connectWallet = async () => {
    if (window.ethereum && active) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        const balance = await provider.getBalance(account);
        const { chainId } = await provider.getNetwork();

        setUser({
          address: account,
          balance: ethers.utils.formatEther(balance)
        });

        if (chainId !== SupportedChainId) {
          setMsg({
            text: "Please connect to the Polygon Mainnet and refresh page",
            color: "pink"
          });
          return;
        }
        setOxODexFactory(new ethers.Contract(contracts.OxODexFactory.address, contracts.OxODexFactory.abi, signer));

        setMsg({ text: "", color: "bisque" });
      } catch (error) {
        console.log(error);
        setMsg({ text: "Error connecting wallet", color: "pink" });
      }
    }
  }

  /**
   * Filter the token list based on the search query
   * @param {string} query
   */
  async function filterToken(query) {
    const value = query.toLowerCase().trim();
    if(value.startsWith("0x") && value.length === 42) {
      const tokenAddress = value;
      // query the blockchain for the token details
      let token = new ethers.Contract(
          tokenAddress,
          contracts.ERC20.abi,
          OxODexFactory.signer
      );

      const tokenDetails = {
        address: tokenAddress,
        decimals: await token.decimals,
        symbol: await token.symbol(),
        name: await token.name(),
        logo: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=',
      }
      setPasteToken(tokenDetails);

    }else{
      const filteredTokens = allTokens.filter(token => token.symbol.toLowerCase().includes(value) || token.name.toLowerCase().includes(value));
      setSortedTokens(filteredTokens);
    }
  }

  return (
    <>  
    <div className="background-body"
      style={{
        backgroundImage: `url(/bg.png)`,
        backgroundPosition: 'center',
        backgroundSize: '100% contain',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
      }}>

      {/* Secret token dialog */}
      <dialog className="dialog-box" id="deposit-dialog">
        <form method="dialog">
          <h2 className="title center">Transaction completed!</h2>
          <p>Keep the token below to be able to withdraw your assets in the future.</p>

          <div style={{ backgroundColor: "#212529", marginTop: "30px" }} className="nes-field">
            <input type="text" id="dark_field" ref={tokenRef} className="tx-complete-input" readOnly={true} />
          </div>
          <div className="copy-clipboard-btn is-success" style={{ marginTop: "5px" }} onClick={() => { navigator.clipboard.writeText(tokenRef.current.value); }}>Copy to Clipboard</div>
          <p style={{ color: "#ff0000" }}>If you lose this token you will not be able to recover your assets!</p>
          <menu className="dialog-menu" style={{ padding: "0px" }}>
            <button onClick={() => { setDepositTokens(depositTokens => [...depositTokens, tokenRef.current.value]) }} className="tx-complete-btn is-success" style={{ marginTop: "25px" }}>I have kept the token</button>
          </menu>
        </form>
      </dialog>
      <div id="overlay"></div>
      {/* End secret token dialog */}

      {/* Select token dialog */}
      <dialog className="dialog-box" id="swap-dialog">
        <form method="dialog" style={{width: '400px'}}>
          <h2 className="title center">Select token</h2>

          <div style={{ backgroundColor: "#212529", marginTop: "30px" }} className="rounded-lg">
            <input 
              type="text" 
              ref={tokenSearch} 
              onChange={e => filterToken(e.target.value)}
              className="rounded-lg text-white-900 dark:text-slate-50 text-left text-base font-medium focus:outline-none focus:ring-0 p-0 bg-transparent w-full truncate font-medium without-ring py-2 px-3" 
              placeholder='Search name or paste address' />
          </div>

          
          {pasteToken != null? (
              <div className="mt-1 mb-2 border-b py-2">
                <button onClick={() => {setSwapToken(pasteToken)}} className='border-2 hover:bg-gray-700 rounded-lg flex items-center py-2 px-2 w-full'>
                    <img className='rounded-full' src={pasteToken.logo} alt={pasteToken.name} height={30} width={30} />
                    <div className='flex flex-col items-start'>
                      <span className='text-base ml-4'>{pasteToken.symbol}</span>
                      <span className='text-sm ml-4 text-muted'>{pasteToken.name}</span>
                    </div>
                </button>
                
                </div>
          ): (
            <></>
          )}
          

          <div className='grid grid-cols-1 gap-2 mt-2' style={{maxHeight: '250px', overflowY: 'auto'}}>
              {sortedTokens.map((token, index) => (
                <button key={index} onClick={() => {setSwapToken(token)}} className='border hover:bg-gray-700 rounded-lg flex items-center py-2 px-2'>
                    <img className='rounded-full' src={token.logo} alt={token.name} height={30} width={30} />
                    <div className='flex flex-col items-start'>
                      <span className='text-base ml-4'>{token.symbol}</span>
                      <span className='text-sm ml-4 text-muted'>{token.name}</span>
                    </div>
                </button>
              ))}
          </div>
        </form>
      </dialog>
      <div id="overlay"></div>
      {/* End select token dialog */}

      <Header setOxODexFactory={ setOxODexFactory } setMsg={ setMsg } msg={ msg } account={ account }/>

      <>
        <h1 className="center text-2xl" style={{ paddingTop: "48px", paddingBottom: "30px", fontFamily: "saira", fontWeight: "Bold" }}>Privacy Decentralized Exchange</h1>
        <div className="space-border"></div>
        {/*<p className="center" style={{ width: "50%", margin: "auto", color: "rgba(170,170,170,255)" }}>A non-traceable privacy ERC-20 transactions mixer is a decentralized platform that provides secure and anonymous transaction mixing. It uses smart contract technology to preserve the privacy of the source and destination of transactions, making them untraceable. <br /><br />Additionally, it utilizes a flash-loan feature to generate fees that are distributed to $0x0 token holders. Ideal for users who place a high value on privacy and security.</p>*/}
      </>
      
      {isNetwork ?
        <>
          {active ?
            <>
            <div className="p-4 mx-auto mt-16 mb-[150px] flex flex-col gap-4 w-full" style={{maxWidth: "520px"}}>
              <div className="flex flex-col gap-4">
                  {/* <div className="flex flex-col gap-2 mb-4 sm:mt-10 mt-2">
                      <h1 className="flex items-center gap-2 text-4xl font-medium text-gray-900 dark:text-slate-50 max-h-[36px] sm:max-h-[44px]">
                          Privacy Mixer
                      </h1>
                  </div> */}
                  <div className="flex justify-between items-center">
                      <div className="flex gap-2">
                          <button onClick={() => { setTab(0) }} 
                                className={["btn font-medium text-white flex items-center justify-center gap-2 cursor-pointer transition-all text-base rounded-lg px-4 h-[32px]", tab == 0? 'bg-slate-600/40 hover:dark:bg-slate-600/20' : 'hover:bg-white hover:dark:bg-slate-600/20'].join(' ')}>Deposit</button>
                          <button onClick={() => { setTab(1) }} 
                                className={["btn font-medium text-white flex items-center justify-center gap-2 cursor-pointer transition-all text-base rounded-lg px-4 h-[32px]", tab == 1? 'bg-slate-600/40 hover:dark:bg-slate-600/20' : 'hover:bg-white hover:dark:bg-slate-600/20'].join(' ')}>Withdraw</button>
                      </div>
                      <span></span>
                  </div>
                  <div></div>
              </div>

              <div className="p-5 border rounded-lg">
                  {tab === 0 ? <Deposit OxODexFactory={OxODexFactory} setMsg={setMsg} tokenRef={tokenRef} /> :
                    <Withdraw OxODexFactory={OxODexFactory} setMsg={setMsg} keyRef={tokenRef} swapToken={swapToken}/>}
              </div>
            </div>
              {(depositTokens.length > 0) ?
                <>
                  <div className='token-content'>
                    <h2 className='center'>Tokens generated this session:</h2>
                    <p style={{ color: "#ff0000" }} className="center">Refreshing this window will make these tokens disappear!</p>
                    {depositTokens?.map((d, i) =>
                    (
                      <div style={{ borderTop: "1px solid rgba(255,255,255,0.3)", borderBottom: "1px solid rgba(255,255,255,0.3)" }}>
                        {/*@ts-ignore*/}
                        <h3 className='center'>-- {i + 1} --</h3>
                        <input type="text" value={d} className="tx-complete-input" readOnly={true}/>
                        <div className="copy-clipboard-btn is-success" style={{ marginTop: "20px", marginBottom: "20px" }} onClick={() => { navigator.clipboard.writeText(d); }}>Copy to Clipboard</div>
                      </div>
                    ))}
                  </div>
                </>
                :
                <></>
              }
            </>
            :
            <>
              <div className="body-content">
                <div className="connect-box">
                  <button className="connect-button" onClick={() => { activate(connectors.injected) }}><img src="/images/mm.svg" alt="metam" style={{ width: "24px", height: "24px" }} /> Metamask</button>
                  <button className="connect-button" onClick={() => { activate(connectors.injected) }}><img src="./images/tw.svg" alt="tw" style={{ width: "24px", height: "24px" }} /> TrustWallet</button>
                  <button className="connect-button" onClick={() => { activate(connectors.walletConnect) }}><img src="/images/wc.svg" alt="wc" style={{ width: "24px", height: "24px" }} /> WalletConnect</button>
                  <button className="connect-button" onClick={() => { activate(connectors.coinbaseWallet) }}><img src="/images/cb.svg" alt="cbw" style={{ width: "24px", height: "24px" }} /> Coinbase</button>
                </div>
              </div>
            </>
          }
        </>
        :
        <>
          <div className="body-content">
            <div className="connect-box">
              {!isMetamask ?
                <p>Please install the Metamask Browser Extension or use the Metamask Mobile App.</p>
                :
                <>
                  {isLoadingNetwork ?
                    <p>Checking Network.</p>
                    :
                    <>
                      <p>
                        Wrong Network! Please switch over to the Polygon Mainnet.
                      </p>
                      <button className="connect-button" onClick={switchNetwork}> Switch Network</button>
                    </>
                  }
                </>
              }
            </div>
          </div>
        </>
      }
      <Footer />
    </div>
    </>
  )
}

export default Home;