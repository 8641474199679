import React, { useState, useEffect, useRef } from 'react'
import { BigNumber, ethers } from "ethers";
import { contracts, baseToken } from '../gateway';
import { getWithdrawalData, loadAllPools, getSwapData, getPkandSk, getEvent, longDivision } from '../utils';

const zeroHexify = (value) => {
    if (value.indexOf('0x') !== 0) {
        value = '0x' + value
    }
    return value
}

const Withdraw = ({ OxODexFactory, setMsg, keyRef, swapToken }) => {

    const [ringData, setRingData] = useState(null);
    const [withdrawData, setWithdrawData] = useState(null);
    const tokenRef = useRef(null);
    const recipientRef = useRef(null);
    const [busy, setBusy] = useState(false);
    const [tokenPool, setTokenPool] = useState(null);
    const [wState, setWstate] = useState(0); // 0 for withdrawal and 1 for swap
    const [pools, setPools] = useState([]);
    const [pool, setPool] = useState(null);
    const [tokenPoolToken, setTokenPoolToken] = useState(null);
    const [quoted, setQuoted] = useState({ q: false, msg: '', data: null, outputAamount:0 });
    const defaultToken = baseToken;
    const [slippage, setSlippage] = useState(50);
    const withdrawalType = {
        Direct: 0,
        Swap: 1
    };

    useEffect(() => {
        if(withdrawData !== null){
            setBusy(true);
            _quote().then(() => {
                setBusy(false);
            });
        }
    }, [swapToken, withdrawData]);

    // Read meaning to pool ring metadata
    const getData = async () => {
        if (OxODexFactory === null) {
            return;
        }

        let tokenData = tokenRef.current.value;
        let tData = tokenData.split(":");

        const TokenPool = new ethers.Contract(
            tData[3],
            contracts.OxODexPool.abi,
            OxODexFactory.signer
        );
        setTokenPool(TokenPool);
        let tokenPoolToken = new ethers.Contract(
            await TokenPool.token(),
            contracts.ERC20.abi,
            OxODexFactory.signer
        );
        setTokenPoolToken({
            address: await TokenPool.token(),
            symbol: await tokenPoolToken.symbol(),
            decimals: await tokenPoolToken.decimals(),
            pool: tData[3]
        });

        let amount = tData[1];
        let shared_amount = longDivision(amount, "2");
        let ringIndex = tData[2];
        let SecretKey = tData[4];

        const ringdata = await TokenPool.rings(shared_amount, ringIndex);

        let participants = await TokenPool.getParticipant(ringdata[1]);
        var tc = new ethers.Contract(
            await TokenPool.token(),
            contracts.ERC20.abi,
            OxODexFactory.signer
        );
        let tokenDecimal = await tc.decimals();
        let symbol = await tc.symbol();
        let status = "Closed";

        let ringHash = await TokenPool.getRingHash(shared_amount, ringIndex);
        let publicKeys = await TokenPool.getPublicKeys(shared_amount, ringIndex);

        if (ringHash === "0x0000000000000000000000000000000000000000000000000000000000000000") {
            setMsg({ color: "255,192,203", text: "Ring isn't open" });
            status = "Open";
        }

        setWithdrawData({
            ringHash: ringHash,
            tokenAmount: amount,
            sharedAmount: shared_amount,
            ringIndex: ringIndex,
            secret: SecretKey,
        });

        let rData = {
            token: symbol,
            amount: ethers.utils.formatUnits(amount, tokenDecimal),
            status: status,
            participants: parseInt(participants._hex),
            ringId: parseInt(ringIndex),
            publicKeys: publicKeys
        };
        console.log(rData);
        setRingData(rData);
    }

    const getTokenPool = async (tokenAddress) => {
        if (OxODexFactory === null) {
            return;
        }
        let poolAdress = await OxODexFactory.getPool(tokenAddress);
        return poolAdress;
    }

    const swapWithdrawal = async () => {
        setBusy(true);

        if (quoted.q === false) {
            setMsg({ color: "255,192,203", text: "Quote not yet generated" });
            setBusy(false);
            return;
        }

        let recipient = recipientRef.current?.value;
        if (!recipient) {
            setMsg({ color: "255,192,203", text: "Recipient address is required" });
            setBusy(false);
            return;
        }

        if (withdrawData === null || tokenPool === null) {
            setMsg({ color: "255,192,203", text: "Invalid token data" });
            setBusy(false);
            return;
        }

        const params = getWithdrawalData(ringData.publicKeys, withdrawData, recipient, setMsg);
        try {
            let encodedSwapData = quoted.data["encodedSwapData"];
            let routerAddress = quoted.data["routerAddress"];
            let tokenOut = getTokenOut();

            let gasEstimate = await tokenPool.estimateGas.swapOnWithdrawal(
                tokenOut,
                routerAddress,
                encodedSwapData,
                recipient,
                withdrawData.tokenAmount,
                zeroHexify(withdrawData.ringIndex.toString(16)),
                params.c0,
                params.keyImage,
                params.s,
            );

            let tx = await tokenPool.swapOnWithdrawal(
                tokenOut,
                routerAddress,
                encodedSwapData,
                recipient,
                withdrawData.tokenAmount,
                zeroHexify(withdrawData.ringIndex.toString(16)),
                params.c0,
                params.keyImage,
                params.s,
                { gasLimit: gasEstimate }
            );
            const receipt = await tx.wait();

            if (receipt.status === 1) {
                setMsg({ text: "Swap Successful", color: "54, 210, 205" });
            } else {
                setMsg({ text: "Swap Failed", color: "255,192,203" });
            }
        } catch (error) {
            console.log(error);
            setMsg({ color: "255,192,203", text: "Error while swapping" });
        }

    }

    // Initiate withdrawal transaction
    const withdraw = async () => {
        setBusy(true);
        let recipient = recipientRef.current?.value;
        if (!recipient) {
            setMsg({ color: "255,192,203", text: "Recipient address is required" });
            setBusy(false);
            return;
        }

        if (withdrawData === null || tokenPool === null) {
            setMsg({ color: "255,192,203", text: "Invalid token data" });
            setBusy(false);
            return;
        }

        if (ringData.status === "Open") {
            setMsg({ text: "Ring not yet closed, wait until more users enter.", color: "255,192,203" });
            setBusy(false);
            return;
        }

        const params = getWithdrawalData(ringData.publicKeys, withdrawData, recipient, setMsg);

        try {
            let withdrawAmount = withdrawData.tokenAmount;
            let gasEstimate = await tokenPool.estimateGas.withdraw(
                recipient,
                withdrawAmount,
                zeroHexify(withdrawData.ringIndex.toString(16)),
                params.c0,
                params.keyImage,
                params.s,
                withdrawalType.Direct
            );

            let tx = await tokenPool.withdraw(
                recipient,
                withdrawAmount,
                zeroHexify(withdrawData.ringIndex.toString(16)),
                params.c0,
                params.keyImage,
                params.s,
                withdrawalType.Direct,
                { gasLimit: gasEstimate }
            );
            const receipt = await tx.wait();

            if (receipt.status === 1) {
                setMsg({ text: "Withdrawal Successful", color: "54, 210, 205" });
            } else {
                setMsg({ text: "Withdrawal Failed", color: "255,192,203" });
            }

        } catch (error) {
            // console.log(error.message);
            setMsg({text: "Withdrawal Failed", color: "255,192,203"});
        }

        setBusy(false);
    }

    const getTokenOut = () => {
        return swapToken.address;
    }

    // Quote swap
    const _quote = async () => {
        setBusy(true);
        
        // let tokenIn = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"; // ethereum WETH
        let tokenIn = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"; // polygon wmatic
        let tokenOut = getTokenOut();
        let withdrawAmount = withdrawData.tokenAmount;

        let qData = await getSwapData(tokenPoolToken.pool, withdrawAmount, tokenIn, tokenOut, slippage);
        if (qData.tokens) { 
            let outputAmount = ethers.utils.formatUnits(qData.outputAmount, swapToken.decimals);
            let inputAmount = ethers.utils.formatUnits(qData.inputAmount, 18);
            let ap_outputAmount = parseFloat(outputAmount).toFixed(5);
            let ap_inputAmount = parseFloat(inputAmount).toFixed(5);

            setQuoted({ q: true, msg: '', outputAamount: ap_outputAmount, data: qData });
        } else {
            setQuoted({ q: false, msg: "Error getting quote", outputAamount:0 });
        }
        setBusy(false);
    };

    // Quote swap every 8 seconds
    const quote = async () => {
        // setInterval(async () => {
        //     await _quote();
        // }, 8000);
        await _quote();
    }

    const setSlippageCustom = (strValue) => {
        let value = parseFloat(strValue);
        if (value > 0 && value < 21) {
            let calc = (value / 100) * 10000;
            setSlippage(calc);
        }
    }

    const toggleSlippage = (e) => {
        document.getElementById("slippageController").classList.toggle("hidden");
        let toggler = document.getElementById("slippageToggle")
        toggler.setAttribute(
            "aria-expanded", 
            toggler.getAttribute("aria-expanded") === "true" ? "false" : "true");
    }

    return (
        <>
            <div className="my-3">
                <div className="w-full border relative pr-10 rounded-xl flex items-center px-3 py-2.5 h-[55px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ffffff" className="bi bi-key" viewBox="0 0 16 16">
                        <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                        <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <input ref={tokenRef} placeholder="Secret Key" onChange={getData} className="ml-3 truncate font-semibold w-full bg-transparent !p-0 placeholder:font-medium placeholder:text-white-400 placeholder:dark:text-slate-500 text-white-900 dark:text-slate-200 border-none focus:outline-none focus:ring-0" autoCorrect="off" autoCapitalize="off" spellCheck="false" autoComplete="off" />
                </div>
            </div>

            <div className="space-y-2 overflow-hidden pb-2 p-3 border rounded-xl">
                <div className="relative flex items-center gap-4">
                    <input inputMode={"decimal"} title="Token Amount" autoCorrect="off" autoCapitalize="off" spellCheck="false" type="text" placeholder="0" min="0" className="text-white-900 dark:text-slate-50 text-left text-base font-medium border-none focus:outline-none focus:ring-0 p-0 bg-transparent w-full truncate font-medium without-ring !text-3xl py-1" value={ringData != null? ringData.amount : 0} readOnly={true} />
                    <button className="text-white flex items-center gap-1 text-xl py-2 pl-2 pr-2 rounded-full font-medium bg-black/[0.06] hover:bg-black/[0.12] dark:bg-white/[0.06] hover:dark:bg-white/[0.12]">
                        <div className="w-[28px] h-[28px] mr-0.5">
                            <span>
                                <img alt="Ether" src="https://cdn.sushi.com/image/upload/f_auto,c_limit,w_48,q_auto/tokens/1/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0.jpg" className="rounded-full"/>
                            </span>
                        </div>{defaultToken}
                    </button>
                </div>
                <div className="flex flex-row items-center justify-between h-[36px]">
                    <p className="font-medium text-lg flex items-baseline select-none text-gray-500 dark:text-slate-400">$ 0.<span className="text-sm font-semibold">00</span>
                    </p>
                    <span></span>
                </div>
            </div>

            {/* <div className="nes-container with-title is-dark status">
                <h3 className="center">Status</h3>
                {
                    ringData != null ? (
                        ringData.status ? (
                            <div className='wd-status center'>
                                <p>Ring status: <span className="is-success">{ringData.status}</span></p>
                                <p>No of Participants: <span className="is-success">{ringData.participants}</span></p>
                                <p>Ring ID: <span className="is-success">{ringData.ringId}</span></p>
                                <p>Amount: <span className="is-success">{ringData.amount} {ringData.token}</span></p>
                            </div>
                        ) : (
                            <h3 className="center">Loading...</h3>
                        )
                    ) : (
                        <p className='center' style={{ color: "bisque" }}>Input a valid token.</p>
                    )
                }
            </div> */}

            <div className="w-100 border rounded-3xl p-1 my-2 grid grid-cols-2 gap-4 tab">
                <button className={["py-1 px-4 rounded-2xl w-full", (wState == 0 ? "selected" : "")].join(" ")} onClick={() => { setWstate(0) }}>
                    Withdraw 
                </button>
                <button className={["py-1 px-4 rounded-2xl w-full", (wState == 1 ? "selected" : "")].join(" ")} onClick={() => { setWstate(1) }}>
                    Swap
                </button>
            </div>

            <>
                {wState == 1 ? (
                    <>
                        <div className="space-y-2 overflow-hidden pb-2 p-3 border rounded-xl">
                            <div className="relative flex items-center gap-4">
                                <input inputMode={"decimal"} title="Token Amount" autoCorrect="off" autoCapitalize="off" spellCheck="false" autoComplete="new-password" type="text" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0" min="0" minLength="1" maxLength="79" className="text-white-900 dark:text-slate-50 text-left text-base font-medium border-none focus:outline-none focus:ring-0 p-0 bg-transparent w-full truncate font-medium without-ring !text-3xl py-1" value={quoted.outputAamount} readOnly={true} />
                                <button onClick={() => {document.getElementById("swap-dialog").setAttribute("open", "")}} className="text-white flex items-center gap-1 text-xl py-2 pl-2 pr-2 rounded-full font-medium bg-black/[0.06] hover:bg-black/[0.12] dark:bg-white/[0.06] hover:dark:bg-white/[0.12]">
                                    <div className="w-[28px] h-[28px] mr-0.5">
                                        <span className='sp1'>
                                            <span className='sp2'>
                                                <img alt="" className="img1" aria-hidden="true" src={swapToken.logo}/>
                                            </span>
                                            <img alt={swapToken.name} srcSet={swapToken.logo} src={swapToken.logo} decoding="async" data-nimg="intrinsic" className="rounded-full img2"/>
                                        </span>
                                    </div>
                                    {swapToken.symbol}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" aria-hidden="true" className="ml-1" width="16" height="16">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-row items-center justify-between h-[36px]">
                                <p className="font-medium text-lg flex items-baseline select-none text-gray-500 dark:text-slate-400">$ 0.<span className="text-sm font-semibold">00</span>
                                </p>
                                <span></span>
                            </div>
                        </div>

                        <div className='my-2 flex items-center'>
                            <span className='flex items-center text-sm text-gray-400'>Max Slippage
                                <span className='flex items-center tooltip'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className='mx-1 cursor-pointer' width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>:
                                    <span className="tooltiptext shadow w-[300px]">
                                        During the swap if the price changes by more than this %, your transaction will revert. 
                                        The maximum allowed slippage is <b>20%</b>.
                                    </span>
                                </span>
                            </span>
                            <span className='mx-2'>{(slippage / 10000) * 100}%</span>
                            <button className='bg-transparent border-none' id="slippageToggle" onClick={toggleSlippage} aria-expanded="false">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z" fill="currentColor"></path></svg>
                            </button>
                        </div>

                        <div className="w-100 border rounded-3xl p-1 my-2 grid grid-cols-5 gap-2 tab hidden" id="slippageController">
                            <button className={["py-1 px-4 rounded-2xl w-full text-sm", (slippage == 5 ? "selected" : "")].join(" ")} onClick={() => { setSlippage(5) }}>
                                0.05%
                            </button>
                            <button className={["py-1 px-4 rounded-2xl w-full text-sm", (slippage == 10 ? "selected" : "")].join(" ")} onClick={() => { setSlippage(10) }}>
                                0.1%
                            </button>
                            <button className={["py-1 px-4 rounded-2xl w-full text-sm", (slippage == 50 ? "selected" : "")].join(" ")} onClick={() => { setSlippage(50) }}>
                                0.5%
                            </button>
                            <button className={["py-1 px-4 rounded-2xl w-full text-sm",(slippage == 100 ? "selected" : "")].join(" ")} onClick={() => { setSlippage(100) }}>
                                1%
                            </button>
                            <div className='flex items-center w-full px-2'>
                                <input placeholder='Custom' className='w-full bg-transparent border-none outline-none text-sm' type="text" min="0" max="100" step="2.5" onChange={e => { setSlippageCustom(e.target.value) }} />
                                <span className='text-sm'>%</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className="">
                    <div className="w-full border relative pr-10 rounded-xl flex items-center px-3 py-2.5 h-[55px]">
                        <input ref={recipientRef} placeholder="Recipient address" className="ml-3 truncate font-semibold w-full bg-transparent !p-0 placeholder:font-medium placeholder:text-white-400 placeholder:dark:text-slate-500 text-white-900 dark:text-slate-200 border-none focus:outline-none focus:ring-0" autoCorrect="off" autoCapitalize="off" spellCheck="false" autoComplete="off" />
                    </div>
                </div>
            </>
            <br />
            <div className="flex items-center justify-start w-full">
                <span className="mr-4 flex items-centertext-base text-gray-400">
                    <span>Use Relayer</span>
                    <span className='flex items-center tooltip'>
                        <svg xmlns="http://www.w3.org/2000/svg" className='mx-1 cursor-pointer' width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>:
                        <span className="tooltiptext shadow text-xs w-[120px] text-center">
                           (Coming soon)
                        </span>
                    </span>
                </span>
                <label htmlFor="default-toggle" className="m-0 inline-flex relative items-center cursor-not-allowed">
                    <input type="checkbox" disabled id="default-toggle" className="sr-only peer"/>
                    <div className="w-11 h-6 bg-gray-200 outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600"></div>
                </label>
            </div>
            <br />
            <>
                {wState == 0 ? (
                    <div className='deposit-button-container w-full'>
                        <button type="button" onClick={withdraw} className={["deposit-button is-success", withdrawData === null || busy ? "is-disabled" : ""].join(" ")}>
                            Withdraw
                        </button>
                    </div>
                ) : (
                    quoted.q ? (
                        <div className='deposit-button-container'>
                            <button type="button" onClick={swapWithdrawal} className={["deposit-button is-success", withdrawData === null || busy ? "is-disabled" : ""].join(" ")}>
                                Swap
                            </button>
                        </div>
                    ) : (
                        <div className='deposit-button-container'>
                            <button type="button" onClick={quote} className={["deposit-button is-success", withdrawData === null || busy ? "is-disabled" : ""].join(" ")}>
                                Quote
                            </button>
                        </div>
                    )
                )}
            </>
        </>
    )
}

export default Withdraw;