import { serialize, h1, bn128 } from './lib/AltBn128';
import crypto from 'crypto';
import BN from 'bn.js'
import { contracts } from './gateway';
import { ethers } from "ethers";

/**
 * Generate the stealth key pair
 * @returns {object} the stealth key pair
 * @returns {string} the stealth key pair.rsk the random secret key
 * @returns {string} the stealth key pair.ssk the stealth secret key
 * @returns {array} the stealth key pair.spk the stealth public key
 */
function getPkandSk(){
    let randomSecretKey = crypto.randomBytes(32).toString('hex');
    let stealthSecretKey = h1(
        serialize([randomSecretKey, ''])
    );

    let stealthPublicKey = bn128.ecMulG(stealthSecretKey).map(x => '0x' + x.toString(16));
    return {
        rsk: randomSecretKey,
        ssk: stealthSecretKey,
        spk: stealthPublicKey
    };
}   

/**
 * Adds a '0x' to the beginning of the string if it is not there
 * @param {string} value the value to be zero hexified
 * @returns {string} the zero hexified value
 */
const zeroHexify = (value) => {
    if (value.indexOf('0x') !== 0) {
        value = '0x' + value
    }
    return value
}

/**
 * Generate the withdrawal data and sign the message
 * @param {array} publicKeys the array of public keys
 * @param {object} withdrawData the withdrawal data
 * @param {string} recipient the recipient address
 * @param {function} setMsg the function to set the message
 * @returns {object} the withdrawal data
 * @returns {string} the withdrawal data.c0 the c0
 * @returns {array} the withdrawal data.s the s
 * @returns {array} the withdrawal data.keyImage the keyImage
 */
function getWithdrawalData(publicKeys, withdrawData, recipient, setMsg){
    const bnZero = new BN('0', 10);

    const publicKeysBN = publicKeys.map(x => {
            return [
            new BN(Buffer.from(x[0].slice(2), 'hex')),
            new BN(Buffer.from(x[1].slice(2), 'hex'))
            ]
    }).filter(x => x[0].cmp(bnZero) !== 0 && x[1].cmp(bnZero) !== 0)

    const stealthSecretKey = h1(
        serialize([withdrawData.secret, ''])
    );

    const stealthPublicKey = bn128.ecMulG(stealthSecretKey);
    let secretIdx = 0
    let canSign = false

    for (let i = 0; i < publicKeysBN.length; i++) {
        const curPubKey = publicKeysBN[i]

        if (curPubKey[0].cmp(stealthPublicKey[0]) === 0 && curPubKey[1].cmp(stealthPublicKey[1]) === 0) {
          secretIdx = i
          canSign = true
          break
        }
    }
    
    if(!canSign) {
        setMsg({color: "255,192,203", text: "Invalid secret key"});
        return;
    }

    const message = Buffer.concat([
        Buffer.from(
            withdrawData.ringHash.slice(2), // Remove the '0x'
            'hex'
        ),
        Buffer.from(
            recipient.slice(2), // Remove the '0x'
            'hex'
        )
    ]);

    const signature = bn128.ringSign(
        message,
        publicKeysBN,
        stealthSecretKey,
        secretIdx
    )

    const c0 = zeroHexify(signature[0].toString(16))
    const s = signature[1].map(x => zeroHexify(x.toString(16)))
    const keyImage = [
        zeroHexify(signature[2][0].toString(16)),
        zeroHexify(signature[2][1].toString(16))
    ]

    return {
        'c0': c0,
        's': s,
        'keyImage': keyImage,
    }
}

/**
 * Load all pools from the factory
 * @param {object} OxODexFactory the OxODexFactory contract
 * @returns {array} the array of pools
 */
async function loadAllPools(OxODexFactory){
    let poolsLength = await OxODexFactory.allPoolsLength();
    let pools = [];

    for (let i = 0; i < poolsLength; i++) {
        let pool = await OxODexFactory.allPools(i);
        let poolContract = new ethers.Contract(pool, contracts.OxODexPool.abi, OxODexFactory.signer);
        let token = await poolContract.token();
        let tokenContract = new ethers.Contract(token, contracts.ERC20.abi, OxODexFactory.signer);
        let symbol = await tokenContract.symbol();
        let decimals = await tokenContract.decimals();
        let tokenData = {
            i: i,
            address: token,
            symbol: symbol,
            decimals: decimals,
            "pool": pool
        }
        pools.push(tokenData);
    }
    return pools;
}

/**
 * Generate swap data for KyberSwap
 * @param {string} toAddr the address to send the swapped tokens to
 * @param {number} amount the amount of tokenIn to swap
 * @param {string} tokenIn the address of the token to swap
 * @param {string} tokenOut  the address of the token to receive
 * @param {number} slippageTolerance the slippage tolerance in percentage
 * @returns {object} the swap data
 */
async function getSwapData(toAddr, amount, tokenIn, tokenOut, slippageTolerance){
    // let chain = "ethereum";
    let chain = "polygon";
    let baseUrl = `https://aggregator-api.kyberswap.com/${chain}/route/encode`;
    let params = {
        "tokenIn": tokenIn,
        "tokenOut": tokenOut,
        "amountIn": amount,
        "to": toAddr,
        "slippageTolerance": slippageTolerance
    };

    let url = baseUrl + "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return fetch(url).then(res => res.json());
}

/**
 * Filter the event from the receipt
 * @param receipt is the receipt of the transaction
 * @param abi is the abi of the contract event
 * @returns {object} the event data
 */
function getEvent(receipt, abi){
    let iface = new ethers.utils.Interface(abi);
    let logs = receipt.logs;

    for (let i = 0; i < logs.length; i++) {
        let log = logs[i];
        try{
            let event = iface.parseLog(log);
            return event;
        }catch(e){
            continue;
        }
    }
}

function longDivision(number,divisor)
    {
        // As result can be very
        // large store it in string
        // but since we need to modify
        // it very often so using
        // string builder
        let ans="";
   
        // We will be iterating
        // the dividend so converting
        // it to char array
   
        // Initially the carry
        // would be zero
        let idx = 0;
          let temp=number[idx]-'0';
        while (temp < divisor)
        {
            temp = (temp * 10 +
            (number[idx + 1]).charCodeAt(0) -
                   ('0').charCodeAt(0));
            idx += 1;
        }
        idx += 1;
         
        while(number.length>idx)
        {
            // Store result in answer i.e. temp / divisor
            ans += String.fromCharCode
            (Math.floor(temp / divisor) +
            ('0').charCodeAt(0));
           
            // Take next digit of number
            temp = ((temp % divisor) * 10 +
            (number[idx]).charCodeAt(0) -
                  ('0').charCodeAt(0));
            idx += 1;
        }
         
        ans += String.fromCharCode
        (Math.floor(temp / divisor) +
        ('0').charCodeAt(0));
         
        // If divisor is greater than number
        if(ans.length==0)
            return "0";
        // else return ans
        return ans;
    }

export { getPkandSk, getWithdrawalData, loadAllPools, getSwapData, getEvent, longDivision }