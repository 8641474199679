
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';

function getLibrary(provider) {
  return new Web3(provider)
}

function App() {
  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
            <Routes>
                  <Route index element={<Home/>} />
                  <Route path="*" element={<>404</>} />
            </Routes>
        </BrowserRouter>
      </Web3ReactProvider>
    </div>
  );
}

export default App;
