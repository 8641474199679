import React, { useState, useRef, useEffect } from 'react';
import { contracts, baseToken } from '../gateway';
import { ethers } from "ethers";
import { getPkandSk, loadAllPools, getEvent } from "../utils";
  

const Deposit = ({ OxODexFactory, setMsg, tokenRef }) => {

    const defaultToken = baseToken;

    const [amount, setAmount] = useState(0);
    const [tokens, setTokens] = useState([]);
    const [token, setToken] = useState(null);
    const [wethPool, setWethPool] = useState(null);
    const [approved, setApproved] = useState(false);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (OxODexFactory !== null) {
            loadPools();
        }
    }, [OxODexFactory]);

    // Load all pools and pool tokens
    const loadPools = async () => {
        setBusy(true);
        let pools = await loadAllPools(OxODexFactory);
        setTokens(pools);

        let defaultTokenData = pools.find(pool => pool.symbol === defaultToken);
        setToken(defaultTokenData);

        setWethPool(
            new ethers.Contract(
                defaultTokenData.pool,
                contracts.OxODexPool.abi,
                OxODexFactory.signer
            )
        )
        setBusy(false);
    }

    // Approve token transfer
    const approve = async () => {
        try {
            if (token === null) {
                setMsg({ text: "No token pools", color: "255,192,203" });
                return;
            }

            let wAmount = ethers.utils.parseUnits(amount.toString(), token.decimals).toHexString();
            let fees = await wethPool.getFeeForAmount(wAmount);
            let wAmountInt = parseInt(wAmount) + parseInt(fees);

            var Token = new ethers.Contract(token.address, contracts.ERC20.abi, OxODexFactory.signer);

            let userAddr = await OxODexFactory.signer.getAddress();
            let balance = await Token.balanceOf(userAddr);
            let allowance = await Token.allowance(userAddr, token.pool);

            if (balance < amount) {
                setMsg({ text: "Insufficient balance", color: "255,192,203" });
                return;
            }

            if (balance < wAmountInt) {
                setMsg({ text: "Insufficient balance to cover fees", color: "255,192,203" });
                return;
            }

            if (allowance < wAmountInt) {
                setBusy(true);
                let tx = await Token.approve(token.pool, ethers.constants.MaxUint256);

                // Wait for the transaction to be mined
                let receipt = await tx.wait();

                // Check for any errors in the transaction receipt
                if (receipt.status === 0) {
                    throw new Error("Transaction failed");
                }

                setApproved(true);
                setBusy(false);
            } else {
                setApproved(true);
            }
        } catch (error) {
            console.error(error);

            // Check for a user-rejected transaction error
            if (error.code === 4001) {
                setMsg({ text: "Transaction rejected by user", color: "255,192,203" });
            } else {
                setMsg({ text: "An error occurred during the approval process", color: "255,192,203" });
            }

            setBusy(false);
        }
    };        

    // Deposit tokens
    const deposit = async () => {
        let wAmount = ethers.utils.parseUnits(amount.toString(), token.decimals).toHexString();
        let wAmountInt = parseInt(wAmount)
        let shared_amount = (wAmountInt & -2) / 2;

        if(token === null) {
            setMsg({text: "No token pools", color: "255,192,203"});
            return;
        }

        var TokenPool = new ethers.Contract(
            token.pool,
            contracts.OxODexPool.abi,
            OxODexFactory.signer
        );

        const amountToken = wAmount;
        const sharedAmountToken = ethers.utils.parseUnits(shared_amount.toString(), token.decimals).toHexString();
        const ringIndex = await TokenPool.getCurrentRingIndex(sharedAmountToken);
        
        let u1 = getPkandSk();
        let u2 = getPkandSk();

        const OxODexToken = {
            randomSecretKey: u1.rsk, // random secret key
            amount: parseInt(shared_amount.toString()),
            ringIndex: ringIndex.toHexString()
        }

        let publicKeys = [...u1.spk, ...u2.spk];

        try{
            setBusy(true);
            
            let tx = await TokenPool.deposit(amountToken, publicKeys);
            const receipt = await tx.wait();

            let abi = ["event Deposit(address, uint256 tokenAmount, uint256 ringIndex)"];

            if (receipt.status === 1) {
                // Get deposit event to extract deposit amount and ring index
                let event = getEvent(receipt, abi);
                
                let depositAmount = event.args.tokenAmount.toString();
                let ringIndex = event.args.ringIndex.toHexString();
                
                tokenRef.current.value = `0x0.ai:${depositAmount}:${ringIndex}:${token.pool}:${OxODexToken.randomSecretKey}`;
                document.getElementById("deposit-dialog").setAttribute("open", "");
                setMsg({text: "Deposit successful", color: "54, 210, 205"});
            }else{
                setMsg({text: "Deposit failed", color: "255,192,203"});
            }
            setBusy(false);
        } catch (error) {
            console.log(error.message);
            setMsg({text: "Deposit failed", color: "255,192,203"});
            setBusy(false);
        }
    }   

    const amountIsSet = () => {
        if(amount > 0) {
            return true;
        }
        return false;
    }

    const selectAmount = (e) => {
        let buttons = document.querySelectorAll("#amounts button");
        buttons.forEach(button => {
            button.classList.remove("selected");
        });

        e.target.classList.add("selected");
    }

    return (
        <>
            <p className="text-sm text-gray-300 my-3">Deposit Amount</p>
            <div className="grid grid-cols-3 gap-4 mb-4" id="amounts">
            { token ? (
                <>
                    <button className="p-2 border rounded-xl flex flex-col items-center text-white text-base" onClick={(event) => {setAmount(0.1); selectAmount(event)}}>
                        <span className="mb-2">0.1</span>
                        <span>{token.symbol}</span>
                    </button>
                    <button className="p-2 border rounded-xl flex flex-col items-center text-white text-base" onClick={(event) => {setAmount(0.2); selectAmount(event)}}>
                        <span className="mb-2">0.2</span>
                        <span>{token.symbol}</span>
                    </button>
                    <button className="p-2 border rounded-xl flex flex-col items-center text-white text-base" onClick={(event) => {setAmount(0.5); selectAmount(event)}}>
                        <span className="mb-2">0.5</span>
                        <span>{token.symbol}</span>
                    </button>
                    <button className="p-2 border rounded-xl flex flex-col items-center text-white text-base" onClick={(event) => {setAmount(1); selectAmount(event)}}>
                        <span className="mb-2">1.0</span>
                        <span>{token.symbol}</span>
                    </button>
                    <button className="p-2 border rounded-xl flex flex-col items-center text-white text-base" onClick={(event) => {setAmount(2); selectAmount(event)}}>
                        <span className="mb-2">2.0</span>
                        <span>{token.symbol}</span>
                    </button>
                    <button className="p-2 border rounded-xl flex flex-col items-center text-white text-base" onClick={(event) => {setAmount(5); selectAmount(event)}}>
                        <span className="mb-2">5.0</span>
                        <span>{token.symbol}</span>
                    </button>
                </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
            <br />

            <br />

            {
                !approved ?
                <>
                    <button 
                        type="button" 
                        className={["deposit-button", busy || !amountIsSet()? "is-disabled" : ""].join(" ")} onClick={approve} 
                    >
                        Approve {amountIsSet() ? `${amount} ${token.symbol}` : ""}
                    </button>
                </>
                :
                <>
                    <button 
                        type="button" 
                        className={["deposit-button", busy || !amountIsSet()? "is-disabled" : ""].join(" ")} onClick={deposit} 
                    >
                        Deposit {amountIsSet() ? `${amount} ${token.symbol}` : ""}
                    </button>
                </>
            }
        </>
    )
}

export default Deposit;