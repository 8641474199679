import React, { useState } from 'react';
import { ethers } from "ethers";
import contracts from '../gateway';

const Header = ({ setOxODexFactory, setMsg, msg, account }) => {



    return (
        <>
            <div className="container-header">
                <div className="container-hf">
                    <div className="justify-left">
                        <a href="https://0x0.ai">
                            <img alt="Home" className="logo" src="/images/logo.png" />
                        </a>
                    </div>

                    {(account === undefined) ?
                        <>
                            <input type="text" value={"Not Connected"} className="p-bg" readOnly={true} />
                        </>
                        :
                        <>
                            <input type="text" value={account} className="p-bg" readOnly={true} />
                        </>
                    }
                </div>

            </div>
            {msg.text === "" ? null : 
            <div className="header-msg"
                style={{
                    border: `1px solid rgba(${msg.color}, 1)`,
                    backgroundColor: `rgba(${msg.color}, 0.1)`,
                    maxWidth: "50%",
                    margin: "auto",
                    marginTop: "48px",
                    // visibility: msg.text ? 'visible' : 'hidden',
                    display: msg.text ? 'block' : 'none'
                }}
            >
                <p style={{
                    color: `rgba(${msg.color}, 1)`,
                    fontSize: '14px',
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",
                    marginTop: "0px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "100%"
                }}>
                    {msg.text}
                </p>
            </div>

            }

        </>
    )
}

export default Header;
